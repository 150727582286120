<template>
  <div class="card" v-if="detailInfo.orderInfo.tprocAudit">
    <div class="card-header">
      <slot name="title"></slot>
      <div v-if="type !== 'orderInformation' && type !== 'orderProcess'">
        <i @click="isOpen = !isOpen" v-if="isOpen" class="el-icon-arrow-up"></i>
        <i @click="isOpen = !isOpen" v-else class="el-icon-arrow-down"></i>
      </div>
    </div>
    <div v-if="isOpen" class="card-main">
      <p class="text" v-if="detailInfo.orderInfo.tprocAudit.approveStatus == '3' && type === 'uploadFiles'">审核通知：{{ detailInfo.orderInfo.tprocAudit.approveRemarks }}</p>
      <slot name="main"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardDetail',
  props: {
    type: {
      type: String
    },
    detailInfo: {
      type: Object
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  watch: {
    // 展开审核信息
    'detailInfo.orderInfo.tprocAudit.approveStatus': function(val) {
      if ((val == '1' || val == '3') && this.type === 'review') this.isOpen = true;
    }
  },

  mounted() {
    // 确认收款卡片展示逻辑
    setTimeout(() => {
      if (this.type === 'orderInternalDetail' && this.detailInfo.orderInfo.orderStatus === 'ZFC' && this.$route.query.customer === 'sellder' && this.$route.query.isHandleShow) this.isOpen = true;
    }, 500);
    // 其他卡片展示逻辑
    if (this.type === 'orderInformation' || this.type === 'orderProcess' || this.type === 'signing' || this.type === 'uploadFiles') this.isOpen = true;
  }
};
</script>
<style lang="less" scoped>
.card {
  margin-bottom: 50px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    height: 55px;
    background-color: #eff6ff;
    i {
      font-size: 17px;
      font-weight: 600;
    }
  }
  &-main {
    .text {
      line-height: 20px;
      color: #fff;
      border-radius: 5px;
      padding: 5px 10px;
      word-wrap: break-word;
      word-break: break-all;
      background-color: #f96060;
    }
  }
}
</style>
