var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.detailInfo.orderInfo.tprocAudit
    ? _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-header" },
          [
            _vm._t("title"),
            _vm.type !== "orderInformation" && _vm.type !== "orderProcess"
              ? _c("div", [
                  _vm.isOpen
                    ? _c("i", {
                        staticClass: "el-icon-arrow-up",
                        on: {
                          click: function ($event) {
                            _vm.isOpen = !_vm.isOpen
                          },
                        },
                      })
                    : _c("i", {
                        staticClass: "el-icon-arrow-down",
                        on: {
                          click: function ($event) {
                            _vm.isOpen = !_vm.isOpen
                          },
                        },
                      }),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "card-main" },
              [
                _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "3" &&
                _vm.type === "uploadFiles"
                  ? _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "审核通知：" +
                          _vm._s(
                            _vm.detailInfo.orderInfo.tprocAudit.approveRemarks
                          )
                      ),
                    ])
                  : _vm._e(),
                _vm._t("main"),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }